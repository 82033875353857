<template>
    hello From project: {{ project_id }}
</template>

<script>
import axios from 'axios';

    export default {
        name: 'ProjectPage',
        props: {
            user: {
                type: Object,
                default: null
                },
            project_id: {
                type: String,
                default: null
            },   
        },
        methods: {
            getProjectData() {
                console.log(this.project_id);

                axios.get('https://api.revio.pro/v1/projects/getProject', {
                    params: {
                        project_id: this.project_id
                    },
                    withCredentials: true
                })
                .then(response => {
                    console.log(response);
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error fetching server text:', error);
                    this.loading = false;
                });

            }
        },
        mounted() {
            this.getProjectData();
        }
    }
</script>

<style scoped>

</style>