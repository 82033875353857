import { createApp } from 'vue';
import App from './App.vue';

import { createRouter, createWebHistory } from 'vue-router'; // Import createRouter and createWebHistory

import AllProjects from './components/AllProjects.vue';
import ProjectPage from './components/ProjectPage.vue';
import NotFound from './components/NotFound.vue';

const routes = [
    {
        path: '/',
        redirect: '/projects'
    },
    {
        path: '/projects',
        component: AllProjects,
        name: 'Your Projects',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/projects/:project_id',
        component: ProjectPage,
        name: 'Project',
        meta: { transition: 'fade' },
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: 'Not found',
        meta: { transition: 'fade' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Add navigation guard to update page title
router.beforeEach((to, from, next) => {
    document.title = `${to.name}`; // Customize the website name as needed
    next();
});

const app = createApp(App);

// Use the router
app.use(router);

// Mount the app with the router
app.mount('#app');

