<template>
  <div v-if="!loading" class="layout">
    <header class="d-flex align-items-center j-space-between px-2">
      <img height="22" src="https://assets.revio.pro/images/rv-brand/REVIO.PRO-HORIZONTAL-COLOR-INVERTED.svg" alt="logo"> 
      <div class="d-flex align-items-center">
        <a href="https://api.revio.pro/signout">
          <img width="20" src="https://assets.revio.pro/images/svg/icons/light-sign-out.svg" alt="Sign out">
        </a>
      </div>
    </header>
    <main class="p-relative">
      <router-view v-slot="{ Component, route }">
        <!-- Use a custom transition or fallback to `fade` -->
        <Transition :name="route.meta.transition || 'fade'" mode="out-in">
            <component :user="user" :email="email" @welcome-email="checkEmail" :is="Component" />
        </Transition>
      </router-view>
    </main>
  </div>
  <ContextMenu ref="contextMenu" />  
</template>

<script>
import axios from 'axios';
import AllProjects from './components/AllProjects.vue';
import ContextMenu from './components/ContextMenu.vue';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with successful response
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response && error.response.status === 403) {
      // Handle 403 Forbidden error globally
      window.location.href = "https://accounts.revio.pro/";
    }
    return Promise.reject(error);
  }
);

export default {
  name: 'App',
  components: {
    AllProjects,
    ContextMenu,
  },
  data() {
    return {
      user: null,
      loading: true,
    }
  },
  mounted() {
    this.getUserData();
    document.addEventListener('contextmenu', this.handleContextMenu);
  },
  beforeUnmount() {
    document.removeEventListener('contextmenu', this.handleContextMenu);
  },
  methods: {
    getUserData() {
      axios.get('https://api.revio.pro/v1/accounts/getuser', {
          withCredentials: true
        })
        .then(response => {
          // Update the serverText data property with the response data
          this.user = response.data.user;
          console.log(response);

          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching server text:', error);

          this.loading = false;
        });

    },
    handleContextMenu(event) {
        event.preventDefault();

        // Example: Show a custom context menu component
        this.$refs.contextMenu.show(event.pageX, event.pageY);
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f2f2f2;
  background-color: #181719;
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
  height: 100%; 
  display: grid;
  grid-template-rows: 50px auto;
  height: 100%;
  overflow: hidden;
}

header {
  background: #211d23;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  z-index: 4999;
}


</style>
