<template>
    <div style="background: #242226;" class="d-flex align-items-center p-2" v-cloak v-if="user">
      <div>
        <div :style="{ 'background-image': 'url(' + user.picture + ')' }" :data-initial=" user.picture ? '' : user.firstName[0].toUpperCase()" class="user-thumbnail mr-1"></div>
      </div>
      <div class="ml-1 w-100">
        <div class="user-name">{{ user.firstName + ' ' + user.lastName }}</div>
        <div class="user-email">{{ user.email }}</div>
      </div>
      <div class="ml-2">
        <img width="20" src="https://assets.revio.pro/images/svg/icons/light-chevron-down.svg" alt="Account Options">
      </div>
    </div>
</template>

<script>
/*import axios from 'axios';

// Set up Axios interceptors
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 403) {
      // Redirect to login page
      window.location.href = 'https://accounts.revio.pro/welcome';
  }
  return Promise.reject(error);
});*/

export default {
  name: 'HelloWorld',
  props: {
    user: {
      type: Object,
      default: null
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[v-cloak] {
  display: none;
}


.user-name {
  font-size: 14px;
  font-weight: 300;
  color: #f2f2f2;
}

.user-email {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.user-thumbnail {
  position: relative;
  width: 36px;
  height: 36px;
  background: rgba(255,255,255,0.2);
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
  border: 1px solid rgba(255,255,255,0.2);
  background-repeat: no-repeat;
}

[data-initial]::after{
  content: attr(data-initial);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
  font-size: 14px;
  font-family: "Montserrat";
  color: #fff;
}

</style>
