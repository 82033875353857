<template>
    <div
      ref="contextMenu"
      class="context-menu"
      :style="{ top: `${top}px`, left: `${left}px`, display: displayMenu ? 'block' : 'none' }"
    >
      <!-- Context menu items -->
      <div @click="handleMenuItemClick('item1')">Item 1</div>
      <div @click="handleMenuItemClick('item2')">Item 2</div>
      <!-- Add more items as needed -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        top: 0,
        left: 0,
        displayMenu: false
      };
    },
    methods: {
      show(x, y) {
        this.top = y;
        this.left = x;
        this.displayMenu = true;
        // Attach click event listener to the document
        document.addEventListener('click', this.handleClickOutside);
      },
      hide() {
        this.displayMenu = false;
        // Remove click event listener from the document
        document.removeEventListener('click', this.handleClickOutside);
      },
      handleClickOutside(event) {
        const menuElement = this.$refs.contextMenu;
        // Check if the clicked element is outside the context menu
        if (!menuElement.contains(event.target)) {
          this.hide(); // Hide the context menu
        }
      },
      handleMenuItemClick(item) {
        // Handle menu item click logic here
        console.log(`Clicked on ${item}`);
        this.hide(); // Hide the context menu after clicking a menu item
      }
    },
    beforeUnmount() {
      // Ensure the click event listener is removed when the component is destroyed
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
  </script>
  
  <style scoped>
  .context-menu {
    position: fixed;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>