<template>
    <div class="panels">
        <div class="side-panel">
            <HelloWorld :user="user"/>
            <ul class="project-list">
                <li @click="loadProject(project)" :class="{ 'selected': loadedProject.id === project.id }" v-for="project in projects" :key="project.id">
                    {{ project.name }}
                </li>  
            </ul>
        </div>
        <div class="center-panel p-relative">
            <div v-if="loading" class="absolute-center text-center">Please wait...</div>
            <div v-if="!loading && projects.length === 0" class="absolute-center text-center py-5">
                <img width="150" class="mb-5" src="https://assets.revio.pro/images/ilustrations/nothing-to-see.svg" alt="nothing-to-see">
                <h4>You have no projects!</h4>
                <p>Create a project and start to upload videos. </p>
                <button class="d-inline-block w-auto rv-button mt-5">Create Project</button>
            </div>
            <div v-if="loadedProject">
                <div>id: {{ loadedProject.id }}</div>
                <div>name: {{ loadedProject.name }}</div>
            </div>
        </div>
        <div class="side-panel">
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import HelloWorld from './HelloWorld.vue';

    export default {
        name: 'AllProjects',
        components: {
            HelloWorld,
        },
        props: {
            user: {
                type: Object,
                default: null
                },
        },
        data() {
            return {
                loading: true,
                projects: [],
                loadedProject: null,
            }
        },
        methods: {
            listProjects() {
                axios.get('https://api.revio.pro/v1/projects/listProjects', {
                    withCredentials: true
                })
                .then(response => {
                    console.log(response.data);
                    this.projects = response.data;

                    if ( this.projects.length > 0 && this.loadedProject === null ){
                        this.loadedProject = this.projects[0];
                    }

                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error fetching server text:', error);
                    this.loading = false;
                });
            },
            loadProject(project){
                this.loadedProject = project;
            }
        },
        mounted () {
         this.listProjects();
        },
    }
</script>

<style scoped>

.panels {
    display: flex;
    height: 100%;
    height: 100%;
}

.side-panel {
    background: #1f1d21;
    height: 100%;
    width: 100%;
    max-width: 350px;
}

.center-panel {
    background: transparent;
    height: 100%;
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  color: #f2f2f2;
  font-size: 16px;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #b3b3b3;
}

.project-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 16px 8px;
 } 

 .project-list > li {
    padding: 12px 8px;
    font-size: 14px;
    border-radius: 6px;
    color: #f2f2f2;
    font-weight: 300;
 }

 .project-list > li.selected {
    background: #312c33;
 }

</style>